import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { PlatformService } from '../../services/platform.service';

@Component({
  selector: 'app-carousel',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss',
})
export class CarouselComponent {
  logos = [
    {
      image: '../../../../assets/images/slider/374591.webp',
      alt: 'الموارد البشرية والتنمية الاجتماعية ',
    },
    {
      image: '../../../../assets/images/slider/arabic-logo-nvg.webp',
      alt: 'المنصة الوطنية للعمل التطوعي',
    },
    {
      image: '../../../../assets/images/slider/logo najiz.webp',
      alt: 'منصة ناجز',
    },
    {
      image: '../../../../assets/images/slider/logo-ncnp 1.webp',
      alt: 'تراضي',
    },
    {
      image: '../../../../assets/images/slider/logo_tradhi.webp',
      alt: 'المركز الوطني لتنمية القطاع غير الربحي',
    },
    {
      image: '../../../../assets/images/slider/moj-logo.webp',
      alt: 'وزارة العدل',
    },
    {
      image: '../../../../assets/images/slider/sba-logo.webp',
      alt: 'الهيئة السعودية للمحامين',
    },
    {
      image: '../../../../assets/images/slider/vision2030.webp',
      alt: 'رؤية 2030',
    },
    {
      image: '../../../../assets/images/slider/logo-labayh.webp',
      alt: 'لبيه',
    },
  ];

  @ViewChild('slider') slider!: ElementRef;
  @ViewChild('slideTrack') slideTrack!: ElementRef;

  isDown = false;
  startX!: number;
  scrollLeft!: number;
  repeatedLogos: any[][] = [];

  ngOnInit(): void {
    this.repeatedLogos = new Array(3).fill(this.logos);
  }

  ngAfterViewInit(): void {
    const slider = this.slider.nativeElement;

    // Mouse events
    slider.addEventListener('mousedown', (e: MouseEvent) =>
      this.onMouseDown(e)
    );
    slider.addEventListener('mouseleave', () => this.onMouseLeave());
    slider.addEventListener('mouseup', () => this.onMouseUp());
    slider.addEventListener('mousemove', (e: MouseEvent) =>
      this.onMouseMove(e)
    );

    // Touch events
    slider.addEventListener('touchstart', (e: TouchEvent) =>
      this.onTouchStart(e)
    );
    slider.addEventListener('touchend', () => this.onTouchEnd());
    slider.addEventListener('touchmove', (e: TouchEvent) =>
      this.onTouchMove(e)
    );
  }

  onMouseDown(e: MouseEvent) {
    this.isDown = true;
    this.slider.nativeElement.classList.add('active');
    this.startX = e.pageX - this.slider.nativeElement.offsetLeft;
    this.scrollLeft = this.slider.nativeElement.scrollLeft;
    this.slider.nativeElement.style.cursor = 'grabbing';
  }

  onMouseLeave() {
    this.isDown = false;
    this.slider.nativeElement.classList.remove('active');
    this.slider.nativeElement.style.cursor = 'grab';
  }

  onMouseUp() {
    this.isDown = false;
    this.slider.nativeElement.classList.remove('active');
    this.slider.nativeElement.style.cursor = 'grab';
  }

  onMouseMove(e: MouseEvent) {
    if (!this.isDown) return;
    e.preventDefault();
    const x = e.pageX - this.slider.nativeElement.offsetLeft;
    const walk = (x - this.startX) * 2; // Adjust scroll speed
    this.slider.nativeElement.scrollLeft = this.scrollLeft - walk;
  }

  onTouchStart(e: TouchEvent) {
    this.isDown = true;
    this.startX = e.touches[0].pageX - this.slider.nativeElement.offsetLeft;
    this.scrollLeft = this.slider.nativeElement.scrollLeft;
  }

  onTouchEnd() {
    this.isDown = false;
  }

  onTouchMove(e: TouchEvent) {
    if (!this.isDown) return;
    const x = e.touches[0].pageX - this.slider.nativeElement.offsetLeft;
    const walk = (x - this.startX) * 2; // Adjust scroll speed
    this.slider.nativeElement.scrollLeft = this.scrollLeft - walk;
  }
}
